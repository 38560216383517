<template>
  <b-container class="mt-4">
    <b-form @submit="filterClubs">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="'Kulüpler'" class="mr-2 text-white"></label>
          <b-button variant="primary" to="/addclub">Kulüp ekle</b-button>
        </template>
        <b-row>
          <b-col>
            <b-form-input placeholder="Ad" v-model="whereText"></b-form-input>
          </b-col>
          <b-col>
            <b-button variant="secondary" type="submit">Gönder</b-button>
          </b-col>
        </b-row>
        <hr />
        <PMSTable
          :tableItems="getClubs"
          :tableFields="getClubFields"
          :editable="true"
          deleteMessage="Kulübü silmek istediğinizden emin misiniz? Kulübün katıldığı yarışma grupları da silinecektir!"
          :pageCount="getClubTotalPageCount"
          :erasable="true"
          @openDetail="openDetail($event)"
          @onPageChanged="onPageChanged"
          @editClick="editClick($event)"
          @multipleDeleteAction="deleteMultiple($event)"
        ></PMSTable>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import PMSTable from "../../components/tables/GeneralTable.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },

  data() {
    return {
      perPage: 8,
      currentPage: 1,
      items: [],
      sortingModes: ["Bölge Seçin", "single", "range"],
      selected: [],
      sortingSelectMode: "Bölge Seçin",

      anotherSortingModes: ["Seçiniz", "single", "range"],
      anotherSortingSelectMode: "Seçiniz",
    };
  },
  computed: {
    ...mapGetters({
      getClubs: "getClubs",
      getClubFields: "getClubFields",
      getClubTotalPageCount: "getClubTotalPageCount",
      getWhereText: "getClubFilter",
    }),
    whereText: {
      get() {
        return this.getWhereText;
      },
      set(value) {
        this.$store.commit("updateClubFilter", value);
      },
    },
  },
  created() {
    this.$store.dispatch("initClubs", this.$router.history.current.query.page);
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },

  methods: {
    onPageChanged(value) {
      this.$store.dispatch("initClubs", value);
    },
    openDetail(index) {
      this.$router.push({
        name: "ClubDetail",
        params: { id: this.getClubs[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    editClick(index) {
      this.$router.push({
        name: "EditClub",
        params: { id: this.getClubs[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deleteClubs", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    filterClubs(event) {
      event.preventDefault();
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
      this.$store.dispatch("initClubs");
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>